html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
}
